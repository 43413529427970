<template>
  <div>
    <b-card>
      <ValidationObserver ref="systemSetting" v-slot="{ handleSubmit }">
        <b-form class="form-top p-4" @submit.prevent="handleSubmit(formSubmit)">
        <b-row>
          <b-col md="6">
            <input-form v-model="systemSetting.systemSetting.key" :name="$t('systemSettings.key')" :label="$t('systemSettings.key')" validate="required" :disabled="disableStatus" />
          </b-col>
          <b-col md="6">
            <input-form v-model="systemSetting.systemSetting.value" :name="$t('systemSettings.value')" :label="$t('systemSettings.value')" validate="required" :disabled="disableStatus" />
          </b-col>
        </b-row>

        <form-btns v-if="currentPage != itemPages.view" :label="submitLabel" :loading="loading" />
      </b-form>
      </ValidationObserver>
    </b-card>
  </div>
</template>
<script>
import { core } from '../../../../config/pluginInit'
import systemSettingsServices from '../services/systemSettings'
import SystemSetting from '../models/SystemSetting'
import moduleItemMixin from '../../../../Mixins/moduleItemMixin'

export default {
  mixins: [moduleItemMixin],
  data () {
    return {
      systemSetting: new SystemSetting()
    }
  },
  methods: {
    async create () {
      systemSettingsServices.create(this.systemSetting).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'administration.systemSettings' })
      }).catch(() => {
        this.loading = false
      })
    },
    async update () {
      systemSettingsServices.update(this.id, this.systemSetting).then(response => {
        this.loading = false
        core.showSnackbar('success', response.data.message)
        this.lastRoute.name ? this.$router.push(this.lastRoute) : this.$router.push({ name: 'administration.systemSettings' })
      }).catch(() => {
        this.loading = false
      })
    }
  },
  mounted () {
    core.index()
  },
  created () {
    if (this.id) {
      systemSettingsServices.findOne(this.id).then(response => {
        this.systemSetting.fillData(response.data.data)
        this.loading = false
      })
    } else {
      this.loading = false
    }
  }
}
</script>
