export default class SystemSetting {
  constructor () {
    this.setInitialValue()
  }

  setInitialValue () {
    this.systemSetting = {
      id: null,
      key: '',
      value: ''
    }
  }

  fillData (data) {
    if (data) {
      this.systemSetting.id = data.id ? data.id : null
      this.systemSetting.key = data.key ? data.key : ''
      this.systemSetting.value = data.value ? data.value : ''
    } else {
      this.setInitialValue()
    }
  }
}
