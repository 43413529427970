import api from "@/axios";

export default {
  findAll() {
    return api().get("system-settings");
  },
  findOne(id) {
    return api().get(`admin/system-settings/${id}`);
  },
  create(data) {
    return api().post("system-settings", data);
  },
  update(id, data) {
    return api().patch(`admin/system-settings/${id}`, data);
  },
};
