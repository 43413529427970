import { render, staticRenderFns } from "./SystemSettingView.vue?vue&type=template&id=879b02fe&"
import script from "./SystemSettingView.vue?vue&type=script&lang=js&"
export * from "./SystemSettingView.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports